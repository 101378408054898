import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Layout from "../components/layout"

const BlogPage = ({data}) => (
  <Layout>
    <div>
      <h1>Blog Page</h1>
      {data.allMarkdownRemark.edges.map(post => (
        <div key={post.node.id}>
          <h3>{post.node.frontmatter.title}</h3>
          <small>{post.node.frontmatter.author} on {post.node.frontmatter.date}</small>
          <br />
          <Link to={post.node.frontmatter.path}>Read More </Link>
          <br />
        </div>
      ))}
    </div>
  </Layout>
)

export default BlogPage

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            author
            date
            path
            title
          }
        }
      }
    }
  }
`